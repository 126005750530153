 <template>
  <auth-content>
    <el-header slot="header" :favorites="false" />

    <div v-if="hasStripeKey()">

      <div>

          <el-alert type="info" v-if="worldOnTrial">
            {{ $t('subscription.trial_alert', {time_left: trialTimeLeft, app_name: appName}) }}
            <div class="mt-1" v-if="!worldHasCard">
            {{ $t('subscription.trial_no_card_alert', {app_name: appName}) }}
            </div>
            <div class="mt-1" v-if="!worldHasAddress">
            {{ $t('subscription.trial_no_address_alert', {app_name: appName}) }}
            </div>
          </el-alert>

          <div class="row">
            <div :class="{
              'col-12': true,
              'order-3': !worldHasCard || !worldHasAddress,
              'order-2': !worldHasCard && worldHasAddress,
              'order-1': worldHasCard && worldHasAddress,
            }">

              <div class="mb-4">
                <div v-if="!subscriptions" class="text-grey"><loader /></div>
                <div v-if="activeSubscriptions">

                  <div class="row justify-content-center">
                    <div
                      class="col-xxl-6 col-xl-8 col-lg-10 pricing-col"
                      v-for="(item, index) in activeSubscriptions"
                      v-if="(worldPlan && item.id == worldPlan.stripe_plan) || item.active == true"
                      :key="item.id"
                    >
                      <div class="p-3 card shadow">
                        <div class="text-center mb-3">
                          <img :src="require('../../assets/images/subscriptions/' + (index + 1) + '.svg')" class="img-sub py-1">
                        </div>
                        <div class="row mb-3 align-items-center">
                          <div class="col-8">
                            <el-card-title class="h5 my-2" :title="$t('subscription.subscriptions.' + index + '.name')" />
                            <div class="text-grey">{{ $t('subscription.contain_users', {nb: item.free_users}) }}</div>
                            <small class="text-grey" v-html="$t('subscription.price_user', {price: currency(item.user_price)})"></small>
                          </div>
                          <div class="col-4 text-right">
                            <div class="text-grey">À partir de </div>
                            <div class="h5 my-0 text-primary font-weight-bold" v-if="item.base_price" v-html="currency(item.base_price)"></div>
                            <div class="text-grey">par mois</div>
                          </div>
                          <div class="col-12" v-if="worldOnTrial && index == 0">
                            <div class="badge badge-info mt-2">{{ $t('subscription.trial') }}</div>
                          </div>
                          <div class="col-12" v-if="(worldPlan && item.id == worldPlan.stripe_plan)">
                            <div class="badge badge-info mt-2 mr-2">{{ $t('subscription.current') }}</div>
                            <div class="badge badge-yellow mt-2" v-if="worldPlan.ends_at">{{ $t('subscription.ends_at', {date:subscriptionTimeLeft}) }}</div>
                          </div>
                        </div>
                        <div class="border-top border-bottom py-1">
                          <div class="row">
                            <div class="my-4 col-md" v-for="(detail, title) in $t('subscription.subscriptions.' + index + '.details')">
                              <small class="font-weight-bold mb-1 d-block text-uppercase">{{title}}</small>
                              <div v-for="(detail_item) in detail">
                                <i class="icon-check text-success mr-1"></i>{{detail_item}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-center">
                          <div class="my-2 text-left text-grey" v-html="$t('subscription.total_price', {price: currency(totalPriceSubscription(item))})"></div>
                          <button v-if="(worldPlan && item.id == worldPlan.stripe_plan) && worldPlan.ends_at" @click="confirmSubscription(item.id)" class="btn btn-yellow w-100">
                            {{ $t('subscription.reactivate') }}
                          </button>
                          <button :disabled="subscriptionLoading" v-if="(!worldPlan || item.id != worldPlan.stripe_plan) && (!subscriptionLoading || item.id !== subscriptionLoadingId)" @click="confirmSubscription(item.id)" class="btn w-100 btn-success text-white">
                            {{ $t('subscription.choose') }}
                          </button>
                          <div class="text-primary mt-2 mb-1 py-2" v-if="(!worldPlan || item.id != worldPlan.stripe_plan) && subscriptionLoading && item.id === subscriptionLoadingId">
                            <loader />
                          </div>
<!--                           <div>
                            <button class="mt-2 btn btn-light border-0 btn-sm">
                              {{ $t('common.know_more') }}
                            </button>
                          </div> -->
                          <div>
                            <button v-if="(worldPlan && item.id == worldPlan.stripe_plan) && !worldPlan.ends_at" @click="openModal('subscription-cancel')" class="btn btn-sm btn-link text-grey">
                              {{ $t('subscription.cancel') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div :class="{
              'col-12': true,
              'order-1': !worldHasCard && worldHasAddress,
              'order-2': worldHasCard,
             }">

              <div class="card card-content" v-if="!worldHasCard || changeCard">
                <div class="card-header">
                  <el-card-title :title="$t('subscription.add_card_title')"/>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                      <card class='stripe-card form-control my-3'
                        :class='{ complete }'
                        :stripe='stripeKey'
                        :options='stripeOptions'
                        @change='complete = $event.complete'
                      />
                      <button v-if="!addCardLoading" class="mt-1 btn btn-success text-white" @click='addCard' :disabled='!complete'>{{ $t('subscription.add_card') }}</button>
                      <button v-if="worldHasCard && !addCardLoading" class="ml-1 mt-1 btn btn-white" @click='changeCard = false'>{{ $t('common.cancel') }}</button>
                      <div v-if="addCardLoading" class="pt-2 pb-1"><loader class="text-primary"></loader></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div :class="{
              'col-12': true,
              'order-1': worldHasCard && !worldHasAddress,
              'order-3': worldHasAddress,
             }">
              <div class="card card-content">
                <div class="card-header">
                  <el-card-title :title="$t('subscription.billing_informations')"/>
                </div>
                <div class="card-body my-2">
                  <div class="row">
                    <div class="col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                      <div class="row mb-2">
                        <div class="col-12">
                      <div class="row mb-2">
                        <div class="col-12">
                          <input v-model="address.billing_name" type="text" class="form-control" :placeholder="$t('common.address.billing_name')">
                        </div>
                      </div>
                          <places
                            :options="{
                              type:'address',
                              templates: {
                                value: function(suggestion) {
                                  return suggestion.name;
                                }
                              }}"
                            v-model="address.address_line_1"
                            class="form-control"
                            :placeholder="$t('common.address.address_line_1')"
                            @change="val => { placesChange(val) }"
                          >
                          </places>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-12">
                          <input v-model="address.address_line_2" type="text" class="form-control form-control-sm" :placeholder="$t('common.address.address_line_2')">
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-4">
                          <input v-model="address.postal_code" type="text" class="form-control" :placeholder="$t('common.address.postal_code')">
                        </div>
                        <div class="col-8">
                          <input v-model="address.city" type="text" class="form-control" :placeholder="$t('common.address.city')">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <input v-model="address.country" type="text" class="form-control" :placeholder="$t('common.address.country')">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button v-if="!addAddressLoading" class="btn btn-success text-white" @click='addAddress'>{{ $t('common.update') }}</button>
                  <div v-if="addAddressLoading" class="pt-2 pb-1"><loader class="text-primary"></loader></div>
                </div>
              </div>

            </div>
          </div>

        <div class="card card-content" v-if="worldHasCard && !changeCard">
          <div class="card-header">
            <el-card-title :title="$t('subscription.world_card_title')"/>
          </div>
          <div class="card-body pt-2">
            <div class="row align-items-center">
              <div class="col-md-6">
                <card-display
                  :name="world.name"
                  :brand="world.plan.card[0].brand"
                  :exp_month="world.plan.card[0].exp_month"
                  :exp_year="world.plan.card[0].exp_year"
                  :last4="world.plan.card[0].last4"
                ></card-display>
              </div>
              <div class="col-md-6">
                <a href="#" class="btn btn-white" @click.prevent="changeCard = true">
                  {{ $t('subscription.world_card_change') }}
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="card card-content" v-if="worldHasCard && worldHasSubscription">
        <div class="card-header">
          <el-card-title :title="$t('subscription.bills')"/>
        </div>
        <div class="card-body pt-2">
          <a :href="billsLink" target="_new" v-if="billsLink" class="btn btn-outline-secondary">{{ $t('subscription.bills_link') }} <i class="icon-new-tab small ml-1" /></a>
          <loader v-else />
        </div>
      </div>

      <el-modal size="sm" :title="$t('subscription.choose')" id="subscription-choose">
        <form @submit.prevent="chooseSubscription(subscriptionLoadingId)">
          <div class="form-check mb-3">
            <label class="form-check-label label-required" v-tooltip="$t('subscription.explanation')">
              <input type="checkbox" required class="form-check-input" v-model="subscription_choose_confirm">
              {{ $t('subscription.choose_confirmation_text') }}
            </label>
            <div v-if="hasTerms">
              <a :href="termsLink" target="_new" class="small">{{ $t('subscription.choose_confirmation_terms_link') }}</a> <i class="icon-new-tab small text-grey" />
            </div>
          </div>
          <div class="text-center">
            <button :disabled="!subscription_choose_confirm" v-if="!subscriptionLoading" type="submit" class="btn btn-lg btn-success text-white">{{ $t('common.submit') }}</button>
            <div v-if="subscriptionLoading" class="pt-1 pb-2"><loader /></div>
          </div>
        </form>
      </el-modal>

      <el-modal size="sm" :title="$t('subscription.cancel')" id="subscription-cancel">
        <form @submit.prevent="cancelSubscription(subscriptionLoadingId)">
          <div v-if="!subscriptionLoading" class="form-check mb-3">
            <label class="form-check-label">
              <input type="checkbox" required class="form-check-input" v-model="subscription_cancel_confirm">
              {{ $t('subscription.cancel_confirmation_text') }}
            </label>
          </div>
          <div class="text-center">
            <button :disabled="!subscription_cancel_confirm" v-if="!subscriptionLoading" type="submit" class="btn btn-lg btn-danger text-white">{{ $t('common.submit') }}</button>
            <div v-if="subscriptionLoading" class="text-center pt-1 pb-2"><loader /></div>
          </div>
        </form>
      </el-modal>

    </div> <!-- v-if VUE_APP_STRIPE_KEY -->


  </auth-content>
</template>

<script>

import { Card, createToken } from 'vue-stripe-elements-plus'
import moment from 'moment'
import Places from 'vue-places'
import store from '../../store'
import router from '../../router'

import {
  WORLD_REQUEST,
  WORLD_UPDATE,
  WORLD_SUBSCRIPTION,
  WORLD_SUBSCRIPTION_CANCEL,
  GLOBAL_SUBSCRIPTIONS,
  WORLD_BILLS,
} from '../../store/mutations-types'

export default {
  name: 'WorldSubscription',
  components: {
    Card,
    Places
  },
  data () {
    return {
      complete: false,
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
      appName: process.env.VUE_APP_TITLE,
      termsLink: process.env.VUE_APP_TERMS_URL,
      subscriptionLoadingId: null,
      subscriptionLoading: false,
      addAddressLoading: false,
      addCardLoading: false,
      changeCard: false,
      worldLoaded: false,
      subscriptions: false,
      subscription_choose_confirm: false,
      subscription_cancel_confirm: false,
      address: {
        billing_name: '',
        address_line_1: '',
        address_line_2: '',
        postal_code: '',
        city: '',
        country: ''
      },
      billsLink: null,
    }
  },
  mounted () {
    // Get subscriptions
    this.$store.dispatch('global/' + GLOBAL_SUBSCRIPTIONS).then(subscriptions => {
      this.subscriptions = subscriptions
    })

    if (this.worldHasCard && this.worldHasSubscription) {
      // Get bills link
      this.$store.dispatch('world/' + WORLD_BILLS, this.world.id).then(link => {
        this.billsLink = link
      })
    }
  },
  methods: {
    addCard () {
      this.addCardLoading = true
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      createToken().then(data => {

        let world = {...this.world}
        delete world.logo // Prevent logo from being reuploaded
        world.stripe_card_token = data.token.id
        world.loadUser = false

        this.$store.dispatch('world/' + WORLD_UPDATE, world).then(() => {
          this.addCardLoading = false
          this.notifSuccess(this.$t('subscription.add_card_success'))
          this.changeCard = false
        })
        .catch(error => {
          this.addCardLoading = false
          this.notifError(error)
        })

      })
    },
    confirmSubscription (subscriptionId) {

      if (!this.worldHasCard) {
        this.notifInfo(this.$t('subscription.world_need_card'))
        return
      }
      if (!this.worldHasAddress) {
        this.notifInfo(this.$t('subscription.world_need_address'))
        return
      }

      this.openModal('subscription-choose')
      this.subscriptionLoadingId = subscriptionId

    },
    chooseSubscription (subscriptionId) {

      if (!this.subscription_choose_confirm) {
        return
      }

      this.subscription_choose_confirm = false
      this.closeModal('subscription-choose')

      this.subscriptionLoading = true
      this.subscriptionLoadingId = subscriptionId

      this.$store.dispatch('world/' + WORLD_SUBSCRIPTION, {
        worldId: this.world.id,
        subscriptionId: subscriptionId
      }).then(() => {
        this.subscriptionLoading = false
        this.subscriptionLoadingId = null
        this.notifSuccess(this.$t('subscription.choose_subscription_success'))
        window.location.reload() // refresh everything
      })
      .catch(error => {
        this.subscriptionLoading = false
        this.subscriptionLoadingId = null
        this.notifError(error)
      })

    },
    cancelSubscription () {

      if (!this.subscription_cancel_confirm) {
        return
      }

      this.subscription_cancel_confirm = false

      this.subscriptionLoading = true
      this.subscriptionLoadingId = null

      this.$store.dispatch('world/' + WORLD_SUBSCRIPTION_CANCEL, this.world.id).then(() => {
        this.subscriptionLoading = false
        this.closeModal('subscription-cancel')
        this.notifSuccess(this.$t('subscription.cancel_subscription_success'))
      })
      .catch(error => {
        this.subscriptionLoading = false
        this.closeModal('subscription-cancel')
        this.notifError(error)
      })

    },
    addAddress () {
      this.addAddressLoading = true

      let world = {...this.world}
      world.billing_name = this.address.billing_name
      world.address_line_1 = this.address.address_line_1
      world.address_line_2 = this.address.address_line_2
      world.postal_code = this.address.postal_code
      world.city = this.address.city
      world.country = this.address.country
      delete world.logo // Prevent logo from being reuploaded

      this.$store.dispatch('world/' + WORLD_UPDATE, world).then(() => {
        this.addAddressLoading = false
        this.notifSuccess(this.$t('subscription.add_address_success'))
      })
      .catch(error => {
        this.addAddressLoading = false
        this.notifError(error)
      })

    },
    placesChange (val) {
      this.address.address_line_1 = val.name
      this.address.address_line_2 = ''
      this.address.postal_code = val.postcode
      this.address.city = val.city
      this.address.country = val.country
    },
    totalPriceSubscription (sub) {
      let price = sub.base_price

      if (this.world.plan && sub.free_users < this.world.plan.users) {
        price+= (this.world.plan.users - sub.free_users) * sub.user_price
      }
      return price
    }
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
    worldOnTrial () {
      return this.$store.getters['auth/worldOnTrial']
    },
    worldHasAddress () {
      return this.world.billing_name && this.world.address_line_1 && this.world.postal_code && this.world.city && this.world.country
    },
    worldHasCard () {
      return this.$store.getters['auth/worldHasCard']
    },
    worldHasSubscription () {
      return this.$store.getters['auth/worldHasSubscription']
    },
    worldPlan () {
      if (!this.worldHasSubscription) {
        return null
      } else {
        return this.world.plan.subscriptions[0]
      }
    },
    trialTimeLeft () {
      return moment(this.world.plan.trial_ends_at).toNow(true);
    },
    subscriptionTimeLeft () {
      if (!this.worldHasSubscription) {
        return null
      } else if (!this.world.plan.subscriptions[0].ends_at) {
        return null
      }
      return moment(this.world.plan.subscriptions[0].ends_at).toNow(true);
    },
    activeSubscriptions () {
      return this.subscriptions.filter(sub => {
        return sub.active
        // return sub.active || (this.worldPlan && this.worldPlan.stripe_plan == sub.id)
      })
    },
    hasTerms () {
      return this.termsLink && this.termsLink != ''
    },
  },
  watch: {
    world: {
      immediate: true,
      handler (val) {
        if (!this.worldLoaded && val !== true) {
          this.address.billing_name = val.billing_name
          this.address.address_line_1 = val.address_line_1
          this.address.address_line_2 = val.address_line_2
          this.address.postal_code = val.postal_code
          this.address.city = val.city
          this.address.country = val.country

          this.worldLoaded = true
        }
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    let isWorldOwner = store.getters['auth/isWorldOwner']
    if (isWorldOwner === true) {
      store.dispatch('world/' + WORLD_REQUEST, to.params.world).then(data => {
        next()
      })
    } else if (isWorldOwner === false) {
      router.push('error/401')
    } else if (typeof(isWorldOwner) == 'undefined') {
      router.push('/')
    }
  },
}
</script>

<style lang="scss" scoped>
.icon-magic-wand.text-grey {
  color: #ccc;
}
.img-sub {
  height: 100px;
  width: auto;
}
</style>
